import React, { Component } from 'react';

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
    this.populateClientData();
  }

  static renderForecastsTable(forecasts) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>ID</th>
            <th>MacAddress</th>
            <th>Date-Time</th>
            <th>Temp in C</th>
            <th>Temp in F</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
              <tr key={forecast.crtId}>
                  <td>{forecast.crtId}</td>
                  <td>{forecast.crtMacAdd}</td>
                  <td>{forecast.crtTime}</td>
                  <td>{forecast.crtTempC}</td>
                  <td>{forecast.crtTempF}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderForecastsTable(this.state.forecasts);

    return (
      <div>
        <h1 id="tabelLabel" >APi for Controller Data</h1>
            <p>This component demonstrates Controller to Cloud Server communication.</p>
        {contents}
      </div>
    );
  }

    async populateClientData() {
//        const token = process.env.REACT_APP_TOP2_API_KEY;
        const response = await fetch('v1/condata');
//            , { headers: { 'Apikey': token } });
      const data = await response.json();
      this.setState({ forecasts: data, loading: false });
    }


}
